<template>
  <b-container class="home">
    <b-row class="mx-3">
      <b-col md="3">
        <h2 class="stores">Stores</h2>
      </b-col>
      <b-col md="3" class="search-options">
        <b-form-select
          v-model="selected"
          :options="options2"
          class="mt-1"
        ></b-form-select>
      </b-col>
      <b-col md="3" class="search-options">
        <b-form-select
          v-model="selectedMarketOption"
          :options="marketOptions"
          @input="distanceFilter"
          class="mt-1"
          v-if="showMarketOption"
        ></b-form-select>
        <div class="d-flex mt-1" v-else>
          <b-form-input
            class="search-storeinput"
            placeholder="Search Store..."
            v-model="storeName"
          ></b-form-input>
          <button
            class="col search-button mt-3 border-radius-r-40"
            @click="storeResult"
          >
            <b-icon icon="search" class="margin-top-4"></b-icon>
          </button>
        </div>
      </b-col>
      <b-col md="3" class="search-options">
        <b-form-select
          v-model="selectedDistance"
          :options="options"
          @input="distanceFilter"
          class="mt-1"
        >
        </b-form-select>
      </b-col>
    </b-row>
    <sections-slider
      :list="newStore"
      subTitle=""
      :hasBackground="true"
      :showTitle="true"
      :hasButton="false"
      :withoutSlider="true"
    >
      <template slot="default" slot-scope="{ listItem }">
        <div class="item-box product-listing-div">
          <router-link
            style="text-decoration: none; color: inherit"
            :to="{
              name: `shopDetails`,
              params: {
                id: listItem._id,
                items: listItem.office.officeName,
              },
            }"
          >
            <items-item :itemWhiteBg="true" :product="listItem"> </items-item>
          </router-link>
        </div>
      </template>
    </sections-slider>
  </b-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      storeName: "",
      selectedDistance: null,
      options: [
        { value: null, text: "Select Distance", disabled: true },
        { value: 6000, text: "Any Stores" },
        { value: 1, text: "1KM" },
        { value: 2, text: "2KM" },
        { value: 5, text: "5KM" },
        { value: 10, text: "10KM" },
        { value: 20, text: "20KM" },
        { value: 30, text: "30KM" },
      ],
      selectedMarketOption: "",
      marketOptions: [{ text: "All Markets", value: "" }],
      selected: "Name",
      options2: [
        { text: "Store Name", value: "Name" },
        { text: "Market", value: "Market" },
      ],
    };
  },
  computed: {
    ...mapState({
      newStore: (state) => state["home"].newStores,
      currentPosition: (state) => state.location.currentPosition,
      homeLocation: (state) => state["home"].defaultLocation,
      marketList: (state) => state["home"].marketList,
    }),
  },
  watch: {
    homeLocation() {
      this.fetchStore();
    },
    selected() {
      if (this.selected === "Name") {
        this.showMarketOption = false;
      }
      if (this.selected === "Market") {
        this.showMarketOption = true;
      }
    },
  },
  methods: {
    ...mapActions({
      fetchNewStoreData: "home/fetchNewStores",
    }),
    fetchStore() {
      const payload = {
        category: "SELLER",
        name: "",
        state: "",
        city: "",
        zip: "",
        street: "",
        market: "",
        lat: this.currentPosition.Lat || 0,
        lng: this.currentPosition.Lng || 0,
        radius: 6000,
        refresh: true,
      };
      if (this.selected === "Name") {
        if (this.storeName) {
          payload.name = this.storeName;
        }
      }
      if (this.selected === "Market") {
        if (this.selectedMarketOption) {
          payload.market = this.selectedMarketOption;
        }
      }
      if (this.selectedDistance) {
        payload.radius = this.selectedDistance;
      }
      this.fetchNewStoreData(payload);
    },
    storeResult() {
      this.fetchStore();
    },
    distanceFilter() {
      this.fetchStore();
    },
    setMarketData() {
      this.marketList.forEach((el) => {
        this.marketOptions.push({
          text: el.name,
          value: el._id,
        });
      });
    },
  },
  mounted() {
    this.fetchStore();
    this.setMarketData();
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style>
.text-grey1 {
  color: #aaa;
  background-color: transparent;
}

.text-grey {
  background-color: #f9f9f9;
}

.product-listing-div {
  display: block !important;
  width: 100% !important;
}
.stores {
  font-weight: 300;
}
.search-button {
  position: absolute;
  top: 0;
  font-size: large;
  z-index: 1;
  right: 5px;
  width: 48px;
  height: 40px;
  padding: 0px;
  background-color: #2a8750 !important;
  color: #fff;
}
.search-storeinput {
  border-radius: 30px !important;
  height: 40px;
}
</style>
